export const landingPage = {
  nav: {
    about: "Acerca de",
    contact: "Contacto",
    faq: "Preguntas frecuentes",
    download: "Descargar",
  },
  comingSoon: "Próximamente",
  hero: {
    title1: "Imagínate poder decir",
    title2: "'sí' a todos los planes",
    title3: "sin pensarlo dos veces",
    subtitle:
      "TuyoPass hace realidad tus sueños con descuentos exclusivos en las mejores experiencias de tu ciudad.",
    cta: "Comienza la aventura",
  },
  secondSection: {
    title1: "Haces",
    highlight1: "más",
    title2: "gastando",
    highlight2: "menos",
    description:
      "¿Cansado de decir 'no' a planes increíbles? TuyoPass te da la libertad de decir 'sí' con descuentos exclusivos para las mejores experiencias. Desde restaurantes hasta aventuras, haz que cada momento cuente.",
  },
  about: {
    title: "Acerca de TuyoPass",
    text: "TuyoPass es tu llave para desbloquear descuentos exclusivos en un amplio espectro de experiencias. Desde acogedores cafés hasta emocionantes aventuras, estamos aquí para alimentar tus pasiones sin romper tu bolsillo. Con TuyoPass, cada día se convierte en una oportunidad para explorar, disfrutar y vivir la vida al máximo, todo mientras mantienes tu billetera feliz.",
  },
  mission: {
    title: "Nuestra Misión",
    text: "Estamos en una misión para democratizar las experiencias. Al conectar a suscriptores inteligentes con descuentos exclusivos, no solo te estamos ahorrando dinero, sino que estamos abriendo puertas a nuevas aventuras, sabores y recuerdos. ¿Nuestro objetivo? Empoderarte para que hagas más de lo que amas, pruebes cosas nuevas y vivas la vida sin límites.",
  },
  vision: {
    title: "Nuestra Visión",
    text: "Imagina un mundo donde las limitaciones presupuestarias no te impidan experimentar las alegrías de la vida. Ese es el mundo que estamos creando con TuyoPass. Visualizamos una comunidad de aventureros, amantes de la comida, entusiastas del fitness y amantes de la cultura, todos empoderados para perseguir sus pasiones con más frecuencia y de manera más asequible.",
  },
  values: {
    title: "Nuestros Valores",
    text: "Aventura: Creemos en decir 'sí' a nuevas experiencias.\nAccesibilidad: Las grandes experiencias deberían estar al alcance de todos.\nComunidad: Estamos construyendo una red de buscadores de experiencias afines.\nValor: Estamos comprometidos a brindarte ofertas y descuentos imbatibles.",
  },
  footer: {
    privacyPolicy: "Política de privacidad",
    termsConditions: "Términos y condiciones",
    contact: "Contacto",
  },
  thirdSection: {
    title: "¿Qué estás esperando?",
    card1: {
      text: "¿Quieres que nuestros usuarios descubran tu negocio? ¡Contáctanos! Nos encantaría que te unas a la TuyoFamilia.",
      button: "Contáctanos",
    },
    card2: {
      text: "Busco sacarle el máximo provecho a cada experiencia disponible en TuyoPass.",
      button: "Descargar",
    },
  },
  fourthSection: {
    title: "Preguntas Frecuentes",
    faq1: {
      question: "¿Qué es TuyoPass?",
      answer:
        "TuyoPass es una plataforma de suscripción que te ofrece descuentos exclusivos en una amplia variedad de experiencias, desde restaurantes y cafeterías hasta actividades de aventura y bienestar.",
    },
    faq2: {
      question: "¿Cómo funciona?",
      answer:
        "Al suscribirte a TuyoPass, obtienes acceso inmediato a descuentos exclusivos en nuestra red de establecimientos asociados. Simplemente muestra tu TuyoPass en el establecimiento para disfrutar de los beneficios.",
    },
    faq3: {
      question: "¿Cuánto cuesta la suscripción?",
      answer:
        "Actualmente estamos en fase de lanzamiento. Únete a nuestra lista de espera para ser uno de los primeros en conocer nuestros precios especiales de lanzamiento.",
    },
    faq4: {
      question: "¿Dónde puedo usar TuyoPass?",
      answer:
        "TuyoPass se puede usar en todos nuestros establecimientos asociados. Estamos constantemente expandiendo nuestra red de socios para ofrecerte más opciones.",
    },
  },
};
