import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { landingPage as enLandingPage } from "./locales/en/landingPage";
import { privacyPolicy as enPrivacyPolicy } from "./locales/en/privacyPolicy";
import { termsAndConditions as enTermsAndConditions } from "./locales/en/termsAndConditions";
import { resetPassword as enResetPassword } from "./locales/en/resetPassword";
import { notFound as enNotFound } from "./locales/en/notFound";

import { landingPage as frLandingPage } from "./locales/fr/landingPage";
import { privacyPolicy as frPrivacyPolicy } from "./locales/fr/privacyPolicy";
import { termsAndConditions as frTermsAndConditions } from "./locales/fr/termsAndConditions";
import { resetPassword as frResetPassword } from "./locales/fr/resetPassword";
import { notFound as frNotFound } from "./locales/fr/notFound";

import { landingPage as esLandingPage } from "./locales/es/landingPage";
import { privacyPolicy as esPrivacyPolicy } from "./locales/es/privacyPolicy";
import { termsAndConditions as esTermsAndConditions } from "./locales/es/termsAndConditions";
import { resetPassword as esResetPassword } from "./locales/es/resetPassword";
import { notFound as esNotFound } from "./locales/es/notFound";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        landingPage: enLandingPage,
        privacyPolicy: enPrivacyPolicy,
        termsAndConditions: enTermsAndConditions,
        resetPassword: enResetPassword,
        notFound: enNotFound,
      },
    },
    fr: {
      translation: {
        landingPage: frLandingPage,
        privacyPolicy: frPrivacyPolicy,
        termsAndConditions: frTermsAndConditions,
        resetPassword: frResetPassword,
        notFound: frNotFound,
      },
    },
    es: {
      translation: {
        landingPage: esLandingPage,
        privacyPolicy: esPrivacyPolicy,
        termsAndConditions: esTermsAndConditions,
        resetPassword: esResetPassword,
        notFound: esNotFound,
      },
    },
  },
  lng: "es", // Default language
  fallbackLng: "es",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
