export const resetPassword = {
  title: "Reset Password",
  subtitle: "Please enter your new password to continue using TuyoPass",
  requirements: {
    minLength: "At least 8 characters",
    hasLetter: "Contains letters",
    hasNumber: "Contains numbers",
    hasSpecial: "Contains special characters",
  },
  input: {
    placeholder: "New Password",
    showPassword: "Show password",
    hidePassword: "Hide password",
  },
  button: "Reset Password",
  messages: {
    success: "Password reset successful! Redirecting...",
    error: "Error: Please check all password requirements are met.",
  },
};
