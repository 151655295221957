import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import LandingPage from "./components/LandingPage/LandingPage";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import WaitingListForm from "./components/Layout/WaitingListForm";
import ResetPassword from "./components/ResetPassword";
import NotFound from "./components/NotFound";

const AppContent: React.FC = () => {
  const [showWaitingList, setShowWaitingList] = useState(true);
  const location = useLocation();

  return (
    <>
      {location.pathname === "/" && showWaitingList && (
        <WaitingListForm onClose={() => setShowWaitingList(false)} />
      )}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsAndConditions />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
