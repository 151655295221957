import React, { useState } from "react";
import {
  Overlay,
  FormContainer,
  FormHeader,
  CloseButton,
  Title,
  Subtitle,
  Input,
  SubmitButton,
  InputGroup,
  Select,
  StatusMessage,
  ImageSection,
  FormSection,
  StyledForm,
  ImageContent,
  AppTagline,
  AppFeatureList,
} from "./WaitingListForm.styles";

interface FormData {
  email: string;
  name: string;
  referralSource?: string;
}

interface WaitingListFormProps {
  onClose?: () => void;
}

const WaitingListForm: React.FC<WaitingListFormProps> = ({ onClose }) => {
  const [formData, setFormData] = useState<FormData>({
    email: "",
    name: "",
    referralSource: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<
    "idle" | "success" | "error"
  >("idle");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Replace with your Google Apps Script web app URL from step 2
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzO9KmAE8HgFYS8bfKhdRtzLbcmt2vlf4jeOj_HjlYu99qPNXR6VMRSZsHWtu-tadaBBQ/exec",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
          mode: "no-cors", // This is important for CORS issues
        }
      );

      console.log(response, "this is the response");

      // Since we're using no-cors, we can't check response.ok
      // Assume success if no error is thrown
      setSubmitStatus("success");
      setTimeout(() => {
        if (onClose) onClose();
      }, 2000);
    } catch (error) {
      setSubmitStatus("error");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <Overlay>
      <FormContainer>
        <ImageSection>
          <ImageContent>
            <AppTagline>
              Haz <span>más</span>, gasta <span>menos.</span>
            </AppTagline>
            <p>TuyoPass revoluciona la forma de disfrutar tu ciudad</p>

            <AppFeatureList>
              <li>Promociones exclusivas en los mejores lugares</li>
              <li>Restaurantes, eventos y actividades con descuentos</li>
              <li>Una Aplicación moderna, simple e intuitiva</li>
            </AppFeatureList>
          </ImageContent>
        </ImageSection>

        <FormSection>
          <FormHeader>
            <Title>Únete a la Lista de Espera</Title>
            <Subtitle>
              ¡Sé el primero en probar y obtén 50% de descuento en tu primer
              mes!
            </Subtitle>
          </FormHeader>

          <StyledForm onSubmit={handleSubmit}>
            <InputGroup>
              <Input
                type="text"
                name="name"
                placeholder="Tu Nombre"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </InputGroup>

            <InputGroup>
              <Input
                type="email"
                name="email"
                placeholder="Tu Correo Electrónico"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </InputGroup>

            <InputGroup>
              <Select
                name="referralSource"
                value={formData.referralSource}
                onChange={handleChange}
              >
                <option value="">¿Cómo te enteraste de nosotros?</option>
                <option value="social">Redes Sociales</option>
                <option value="friend">Amigo/Colega</option>
                <option value="search">Buscador</option>
                <option value="other">Otro</option>
              </Select>
            </InputGroup>

            <SubmitButton type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Uniéndose..." : "Unirse a la Lista"}
            </SubmitButton>

            {submitStatus === "success" && (
              <StatusMessage $success={true}>
                ¡Gracias por unirte! Nos pondremos en contacto pronto.
              </StatusMessage>
            )}

            {submitStatus === "error" && (
              <StatusMessage $success={false}>
                ¡Ups! Algo salió mal. Por favor, inténtalo de nuevo.
              </StatusMessage>
            )}
          </StyledForm>
        </FormSection>
        <CloseButton onClick={onClose} aria-label="Cerrar">
          ✕
        </CloseButton>
      </FormContainer>
    </Overlay>
  );
};

export default WaitingListForm;
