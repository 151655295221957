import { styled } from "styled-components";
import { colors } from "../styles/colors";

export const Container = styled.div`
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: #1a1d21;
  color: ${colors.white};
`;

export const Title = styled.h1`
  font-size: 120px;
  margin: 0;
  color: ${colors.orange1};

  @media (max-width: 768px) {
    font-size: 80px;
  }
`;

export const Subtitle = styled.h2`
  font-size: 24px;
  margin: 20px 0;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

export const Button = styled.button`
  padding: 12px 24px;
  background-color: ${colors.orange1};
  color: ${colors.white};
  border: none;
  border-radius: 999px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${colors.orange2};
  }
`;
