export const resetPassword = {
  title: "Restablecer Contraseña",
  subtitle:
    "Por favor, ingresa tu nueva contraseña para continuar usando TuyoPass",
  requirements: {
    minLength: "Al menos 8 caracteres",
    hasLetter: "Contiene letras",
    hasNumber: "Contiene números",
    hasSpecial: "Contiene caracteres especiales",
  },
  input: {
    placeholder: "Nueva Contraseña",
    showPassword: "Mostrar contraseña",
    hidePassword: "Ocultar contraseña",
  },
  button: "Restablecer Contraseña",
  messages: {
    success: "¡Contraseña restablecida con éxito! Redirigiendo...",
    error: "Error: Por favor verifica que se cumplan todos los requisitos.",
  },
};
