import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "./Layout/Layout";
import { Container, Title, Subtitle, Button } from "./NotFound.styles";

const NotFound: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Layout>
      <Container>
        <Title>{t("notFound.title")}</Title>
        <Subtitle>{t("notFound.subtitle")}</Subtitle>
        <p>{t("notFound.description")}</p>
        <Button onClick={() => navigate("/")}>{t("notFound.button")}</Button>
      </Container>
    </Layout>
  );
};

export default NotFound;
