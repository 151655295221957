import styled, { keyframes } from "styled-components";
import { colors } from "../../styles/colors";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Styled Components
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  animation: ${fadeIn} 0.3s ease-out;

  @media (max-width: 768px) {
    align-items: flex-start;
    justify-content: flex-start;
    overflow-y: auto;
  }
`;

export const FormContainer = styled.div`
  position: relative;
  background-color: rgba(26, 29, 33, 0.95);
  width: 95%;
  max-width: 1200px;
  height: auto;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
  animation: ${fadeIn} 0.3s ease-out;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    height: auto;
    min-height: 100%;
    max-height: none;
    border-radius: 0;
    margin: 0;
    overflow-y: auto;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  border: none;
  color: ${colors.white};
  font-size: 1.5em;
  cursor: pointer;
  line-height: 1;
  border-radius: 50%;
  z-index: 1000;
  width: 40px;
  height: 40px;
  padding: 0;

  @media (max-width: 768px) {
    top: 15px;
    right: 15px;
    font-size: 1.3em;
    width: 36px;
    height: 36px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const ImageSection = styled.div`
  flex: 0 0 45%;
  background-image: url(${require("../../assets/landingPage/landingpage.png")});
  background-size: cover;
  background-position: center;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.8) 0%,
      rgba(0, 0, 0, 0.6) 100%
    );
    z-index: 1;
  }

  @media (max-width: 768px) {
    flex: 0 0 auto;
    height: 35vh;
    min-height: 200px;
  }
`;

export const ImageContent = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 40px;
  color: white;

  @media (max-width: 768px) {
    padding: 20px;
    height: 100%;
    justify-content: center;
  }
`;

export const AppTagline = styled.h1`
  font-size: 3em;
  font-weight: 600;
  margin-bottom: 20px;
  color: ${colors.white};

  span {
    color: ${colors.orange1};
  }

  @media (max-width: 768px) {
    font-size: 1.5em;
    margin-bottom: 5px;
  }
`;

export const AppFeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 10px 0;

  li {
    margin-bottom: 8px;
    font-size: 1.1em;
    display: flex;
    align-items: flex-start;

    &:before {
      content: "✓";
      color: ${colors.orange1};
      font-weight: bold;
      margin-right: 10px;
      flex-shrink: 0;
    }
  }

  @media (max-width: 768px) {
    margin: 5px 0;

    li {
      font-size: 0.9em;
      margin-bottom: 4px;
      line-height: 1.2;
    }
  }
`;

export const FormSection = styled.div`
  flex: 1;
  padding: 50px;
  background-color: #1a1d21;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    justify-content: flex-start;
    background-color: #1a1d21;
  }
`;

export const FormHeader = styled.div`
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

export const Title = styled.h2`
  font-size: 2em;
  color: ${colors.white};
  margin-bottom: 16px;
  font-weight: 800;
  width: 100%;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.5em;
    margin-bottom: 8px;
  }
`;

export const Subtitle = styled.p`
  color: ${colors.white};
  margin-bottom: 0;
  font-size: 1.1em;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 0.8em;
    line-height: 1.2;
    margin-bottom: 15px;
  }
`;

export const AppDescription = styled.div`
  color: ${colors.white};
  margin: 20px 0 30px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  border-left: 4px solid ${colors.orange1};

  p {
    margin: 0;
    line-height: 1.5;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    gap: 10px;
  }
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Input = styled.input`
  padding: 16px;
  border-radius: 8px;
  border: none;
  background-color: #2a3038;
  color: ${colors.white};
  font-size: 16px;

  &::placeholder {
    color: #6c757d;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${colors.orange1};
  }

  @media (max-width: 768px) {
    padding: 14px;
    font-size: 0.9em;
  }
`;

export const Select = styled.select`
  padding: 16px;
  padding-right: 40px;
  border-radius: 8px;
  border: none;
  background-color: #2a3038;
  color: ${colors.white};
  font-size: 16px;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px;

  &::placeholder {
    color: #6c757d;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${colors.orange1};
  }

  option {
    background-color: #2a3038;
    color: ${colors.white};
  }

  @media (max-width: 768px) {
    padding: 14px;
    padding-right: 36px;
    font-size: 0.9em;
    background-position: right 12px center;
  }
`;

export const SubmitButton = styled.button`
  margin-top: 16px;
  padding: 16px;
  border-radius: 999px;
  border: none;
  background: linear-gradient(90deg, ${colors.orange1}, ${colors.orange2});
  color: ${colors.white};
  font-size: 1.1em;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    transform: none;
  }

  @media (max-width: 768px) {
    margin-top: 12px;
    padding: 14px;
    font-size: 1em;
  }
`;

export const StatusMessage = styled.div<{ $success?: boolean }>`
  padding: 16px;
  text-align: center;
  border-radius: 8px;
  background-color: ${(props) =>
    props.$success ? "rgba(40, 167, 69, 0.2)" : "rgba(220, 53, 69, 0.2)"};
  color: ${(props) => (props.$success ? "#28a745" : "#dc3545")};
  font-size: 16px;
  margin-top: 8px;

  @media (max-width: 768px) {
    padding: 10px;
    font-size: 14px;
    margin-top: 6px;
  }
`;
