import styled from "styled-components";
import { FormSection, ImageSection } from "./Layout/WaitingListForm.styles";
import { colors } from "../styles/colors";

export const PageContainer = styled.div`
  min-height: calc(100vh - 80px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;

  ${ImageSection} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  max-width: 500px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  background-color: rgba(26, 29, 33, 0.95);
  backdrop-filter: blur(10px);
  padding: 40px;

  ${FormSection} {
    padding: 0;
  }
`;

export const Requirements = styled.div`
  margin: 10px 0;
  font-size: 14px;
  color: ${colors.white};
`;

export const RequirementItem = styled.div<{ $isValid: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 4px 0;
  opacity: ${(props) => (props.$isValid ? 1 : 0.7)};

  &:before {
    content: "${(props) => (props.$isValid ? "✓" : "○")}";
    color: ${(props) => (props.$isValid ? colors.orange1 : colors.white)};
  }
`;
