import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Layout from "./Layout/Layout";

const Title = styled.h1`
  font-size: 2.5em;
  margin-bottom: 20px;
  color: white;
`;

const Container = styled.section`
  padding: 70px;
  padding-top: 110px;
  max-width: 1200px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
`;

const Section = styled.section`
  margin-bottom: 40px;
  color: white;
  padding: 20px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.04);
    transform: translateY(-2px);
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #f4f4f4;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 10px;
`;

const Paragraph = styled.p`
  margin-bottom: 20px;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1em;
`;

const List = styled.ul`
  margin: 20px 0;
  padding-left: 25px;
`;

const ListItem = styled.li`
  margin-bottom: 12px;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.6;

  &::marker {
    color: #ff7846; // Using the orange accent color from your website
  }
`;

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Container>
        <Title>{t("privacyPolicy.title")}</Title>
        <Paragraph>{t("privacyPolicy.introduction")}</Paragraph>

        <Section>
          <SectionTitle>
            {t("privacyPolicy.informationCollection.title")}
          </SectionTitle>
          <Paragraph>
            {t("privacyPolicy.informationCollection.content")}
          </Paragraph>
          <List>
            {(
              t("privacyPolicy.informationCollection.list", {
                returnObjects: true,
              }) as string[]
            ).map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </List>
          <Paragraph>
            {t("privacyPolicy.informationCollection.additionalInfo")}
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>
            {t("privacyPolicy.thirdPartyAccess.title")}
          </SectionTitle>
          <Paragraph>{t("privacyPolicy.thirdPartyAccess.content")}</Paragraph>
          <List>
            {(
              t("privacyPolicy.thirdPartyAccess.services", {
                returnObjects: true,
              }) as string[]
            ).map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </List>
          <Paragraph>
            {t("privacyPolicy.thirdPartyAccess.disclosure")}
          </Paragraph>
        </Section>

        <Section>
          <SectionTitle>{t("privacyPolicy.optOutRights.title")}</SectionTitle>
          <Paragraph>{t("privacyPolicy.optOutRights.content")}</Paragraph>
        </Section>

        <Section>
          <SectionTitle>{t("privacyPolicy.dataRetention.title")}</SectionTitle>
          <Paragraph>{t("privacyPolicy.dataRetention.content")}</Paragraph>
        </Section>

        <Section>
          <SectionTitle>{t("privacyPolicy.children.title")}</SectionTitle>
          <Paragraph>{t("privacyPolicy.children.content")}</Paragraph>
        </Section>

        <Section>
          <SectionTitle>{t("privacyPolicy.security.title")}</SectionTitle>
          <Paragraph>{t("privacyPolicy.security.content")}</Paragraph>
        </Section>

        <Section>
          <SectionTitle>{t("privacyPolicy.changes.title")}</SectionTitle>
          <Paragraph>{t("privacyPolicy.changes.content")}</Paragraph>
          <Paragraph>{t("privacyPolicy.changes.effectiveDate")}</Paragraph>
        </Section>

        <Section>
          <SectionTitle>{t("privacyPolicy.consent.title")}</SectionTitle>
          <Paragraph>{t("privacyPolicy.consent.content")}</Paragraph>
        </Section>

        <Section>
          <SectionTitle>{t("privacyPolicy.contact.title")}</SectionTitle>
          <Paragraph>{t("privacyPolicy.contact.content")}</Paragraph>
        </Section>
      </Container>
    </Layout>
  );
};

export default PrivacyPolicy;
