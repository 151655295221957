export const resetPassword = {
  title: "Réinitialiser le mot de passe",
  subtitle:
    "Veuillez entrer votre nouveau mot de passe pour continuer à utiliser TuyoPass",
  requirements: {
    minLength: "Au moins 8 caractères",
    hasLetter: "Contient des lettres",
    hasNumber: "Contient des chiffres",
    hasSpecial: "Contient des caractères spéciaux",
  },
  input: {
    placeholder: "Nouveau mot de passe",
    showPassword: "Afficher le mot de passe",
    hidePassword: "Masquer le mot de passe",
  },
  button: "Réinitialiser le mot de passe",
  messages: {
    success: "Mot de passe réinitialisé avec succès ! Redirection...",
    error:
      "Erreur : Veuillez vérifier que toutes les exigences sont respectées.",
  },
};
