import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Layout from "./Layout/Layout";
import {
  FormSection,
  FormHeader,
  Title,
  Subtitle,
  StyledForm,
  InputGroup,
  Input,
  SubmitButton,
  StatusMessage,
  ImageSection,
} from "./Layout/WaitingListForm.styles";
import {
  PageContainer,
  FormContainer,
  Requirements,
  RequirementItem,
} from "./ResetPassword.styles";

interface PasswordRequirements {
  minLength: boolean;
  hasNumber: boolean;
  hasSpecial: boolean;
  hasLetter: boolean;
}

const ResetPassword: React.FC = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState<"idle" | "error" | "success">("idle");
  const [requirements, setRequirements] = useState<PasswordRequirements>({
    minLength: false,
    hasNumber: false,
    hasSpecial: false,
    hasLetter: false,
  });

  useEffect(() => {
    setRequirements({
      minLength: password.length >= 8,
      hasNumber: /\d/.test(password),
      hasSpecial: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      hasLetter: /[a-zA-Z]/.test(password),
    });
  }, [password]);

  useEffect(() => {
    const token = searchParams.get("token");
    if (!token) {
      navigate("/404");
    }
  }, [searchParams, navigate]);

  const isPasswordValid = Object.values(requirements).every(Boolean);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!isPasswordValid) {
      setStatus("error");
      return;
    }

    const token = searchParams.get("token");
    if (!token) {
      navigate("/404");
      return;
    }

    try {
      const response = await fetch(
        "https://staging.api.tuyopass.com/api/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token,
            newPassword: password,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Invalid or expired token");
      }

      setStatus("success");
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } catch (error) {
      setStatus("error");
      console.error("Password reset error:", error);
    }
  };

  return (
    <Layout>
      <PageContainer>
        <ImageSection />
        <FormContainer>
          <FormSection>
            <FormHeader>
              <Title>{t("resetPassword.title")}</Title>
              <Subtitle>{t("resetPassword.subtitle")}</Subtitle>
            </FormHeader>

            <StyledForm onSubmit={handleSubmit}>
              <Requirements>
                <RequirementItem $isValid={requirements.minLength}>
                  {t("resetPassword.requirements.minLength")}
                </RequirementItem>
                <RequirementItem $isValid={requirements.hasLetter}>
                  {t("resetPassword.requirements.hasLetter")}
                </RequirementItem>
                <RequirementItem $isValid={requirements.hasNumber}>
                  {t("resetPassword.requirements.hasNumber")}
                </RequirementItem>
                <RequirementItem $isValid={requirements.hasSpecial}>
                  {t("resetPassword.requirements.hasSpecial")}
                </RequirementItem>
              </Requirements>

              <InputGroup>
                <Input
                  type="password"
                  placeholder={t("resetPassword.input.placeholder")}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </InputGroup>

              <SubmitButton
                type="submit"
                disabled={!isPasswordValid}
                style={{ opacity: isPasswordValid ? 1 : 0.7 }}
              >
                {t("resetPassword.button")}
              </SubmitButton>

              {status === "success" && (
                <StatusMessage $success={true}>
                  {t("resetPassword.messages.success")}
                </StatusMessage>
              )}

              {status === "error" && (
                <StatusMessage $success={false}>
                  {t("resetPassword.messages.error")}
                </StatusMessage>
              )}
            </StyledForm>
          </FormSection>
        </FormContainer>
      </PageContainer>
    </Layout>
  );
};

export default ResetPassword;
